.ctrl {
  display: flex;
  background-color: #282828;
  width: 264px;
  align-items: center;
  padding: 15px 53px;
  justify-content: space-between;
  margin-top: 5px;
  border: 1px solid #313131;
}

.s1 {
  background-image: url("../../static/icons/s_1.png");
  height: 69px;
  width: 69px;
  background-repeat: no-repeat;
  transition: all 0.2s linear;
  background-size: contain;

  &:hover {
    background-image: url("../../static/icons/s_1_y.png");
  }

  &__active {
    background-image: url("../../static/icons/s_1_y.png");
  }
}

.s2 {
  background-image: url("../../static/icons/s_2.png");
  height: 69px;
  width: 69px;
  background-repeat: no-repeat;
  transition: all 0.2s linear;
  background-size: contain;

  &:hover {
    background-image: url("../../static/icons/s_2_y.png");
  }

  &__active {
    background-image: url("../../static/icons/s_2_y.png");
  }
}

.s3 {
  background-image: url("../../static/icons/s_3.png");
  height: 69px;
  width: 69px;
  background-repeat: no-repeat;
  transition: all 0.2s linear;
  background-size: contain;

  &:hover {
    background-image: url("../../static/icons/s_3_y.png");
  }

  &__active {
    background-image: url("../../static/icons/s_3_y.png");
  }
}
