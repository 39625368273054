.ctrl {
  display: flex;
  background-color: #282828;
  width: 336px;
  align-items: center;
  padding: 15px 17px;
  justify-content: space-between;
  margin-top: 5px;
  flex-direction: column;
  border: 1px solid #313131;
  
  &__top {
    width: 345px;
    display: flex;
    justify-content: space-between;

  }

  &__bottom {
    width: 285px;
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
  }
}

.b0 {
  width: 48px;
  height: 48px;
  background-image: url("../../static/icons/b_0.png");
  transition: all 0.2s linear;
  background-size: contain;
  background-repeat: no-repeat;

  &:hover {
    background-image: url("../../static/icons/b_0_y.png");
  }

  &__active{
    background-image: url("../../static/icons/b_0_y.png");
  }
}

.b1 {
  width: 48px;
  height: 48px;
  background-image: url("../../static/icons/b_1.png");
  transition: all 0.2s linear;
  background-size: contain;
  background-repeat: no-repeat;

  &:hover {
    background-image: url("../../static/icons/b_1_y.png");
  }

  &__active{
    background-image: url("../../static/icons/b_1_y.png");
  }
}

.b2 {
  width: 48px;
  height: 48px;
  background-image: url("../../static/icons/b_2.png");
  transition: all 0.2s linear;
  background-size: contain;
  background-repeat: no-repeat;

  &:hover {
    background-image: url("../../static/icons/b_2_y.png");
  }

  &__active{
    background-image: url("../../static/icons/b_2_y.png");
  }
}

.b3 {
  width: 48px;
  height: 48px;
  background-image: url("../../static/icons/b_3.png");
  transition: all 0.2s linear;
  background-size: contain;
  background-repeat: no-repeat;

  &:hover {
    background-image: url("../../static/icons/b_3_y.png");
  }

  &__active{
    background-image: url("../../static/icons/b_3_y.png");
  }
}

.b4 {
  width: 48px;
  height: 48px;
  background-image: url("../../static/icons/b_4.png");
  transition: all 0.2s linear;
  background-size: contain;
  background-repeat: no-repeat;

  &:hover {
    background-image: url("../../static/icons/b_4_y.png");
  }

  &__active{
    background-image: url("../../static/icons/b_4_y.png");
  }
}

.b5 {
  width: 48px;
  height: 48px;
  background-image: url("../../static/icons/b_5.png");
  transition: all 0.2s linear;
  background-size: contain;
  background-repeat: no-repeat;

  &:hover {
    background-image: url("../../static/icons/b_5_y.png");
  }

  &__active{
    background-image: url("../../static/icons/b_5_y.png");
  }
}

.b6 {
  width: 48px;
  height: 48px;
  background-image: url("../../static/icons/b_6.png");
  transition: all 0.2s linear;
  background-size: contain;
  background-repeat: no-repeat;

  &:hover {
    background-image: url("../../static/icons/b_6_y.png");
  }

  &__active{
    background-image: url("../../static/icons/b_6_y.png");
  }
}

.b7 {
  width: 48px;
  height: 48px;
  background-image: url("../../static/icons/b_7.png");
  transition: all 0.2s linear;
  background-size: contain;
  background-repeat: no-repeat;

  &:hover {
    background-image: url("../../static/icons/b_7_y.png");
  }

  &__active{
    background-image: url("../../static/icons/b_7_y.png");
  }
}

.b8 {
  width: 48px;
  height: 48px;
  background-image: url("../../static/icons/b_8.png");
  transition: all 0.2s linear;
  background-size: contain;
  background-repeat: no-repeat;

  &:hover {
    background-image: url("../../static/icons/b_8_y.png");
  }

  &__active{
    background-image: url("../../static/icons/b_8_y.png");
  }
}

.b9 {
  width: 48px;
  height: 48px;
  background-image: url("../../static/icons/b_9.png");
  transition: all 0.2s linear;
  background-size: contain;
  background-repeat: no-repeat;

  &:hover {
    background-image: url("../../static/icons/b_9_y.png");
  }

  &__active{
    background-image: url("../../static/icons/b_9_y.png");
  }
}

.b10 {
  width: 48px;
  height: 48px;
  background-image: url("../../static/icons/b_10.png");
  transition: all 0.2s linear;
  background-size: contain;
  background-repeat: no-repeat;

  &:hover {
    background-image: url("../../static/icons/b_10_y.png");
  }

  &__active{
    background-image: url("../../static/icons/b_10_y.png");
  }
}
