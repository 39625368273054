.ctrl {
  display: flex;
  background-color: #282828;
  width: 336px;
  align-items: center;
  padding: 10px 17px;
  justify-content: space-between;
  margin-top: 5px;
  border: 1px solid #313131;
}

.note {
  background-color: transparent;
  border: none;
}

.n1 {
  background-image: url("../../static/icons/r_1.png");
  height: 69px;
  width: 69px;
  background-repeat: no-repeat;
  transition: all 0.2s linear;
  background-size: contain;

  &:hover {
    background-image: url("../../static/icons/r_1_y.png");
  }

  &__active{
    
    background-image: url("../../static/icons/r_1_y.png");
  }
}
.n2 {
  background-image: url("../../static/icons/r_2.png");
  height: 69px;
  width: 69px;
  background-repeat: no-repeat;
  transition: all 0.2s linear;

  &:hover {
    background-image: url("../../static/icons/r_2_y.png");
  }

  &__active{
    
    background-image: url("../../static/icons/r_2_y.png");
  }
}
.n3 {
  background-image: url("../../static/icons/r_3.png");
  height: 69px;
  width: 69px;
  background-repeat: no-repeat;
  transition: all 0.2s linear;
  
  &:hover {
    background-image: url("../../static/icons/r_3_y.png");
  }
  
  &__active{
    
    background-image: url("../../static/icons/r_3_y.png");
  }
}
.n4 {
  background-image: url("../../static/icons/r_4.png");
  height: 69px;
  width: 69px;
  background-repeat: no-repeat;
  transition: all 0.2s linear;
  background-size: contain;

  &:hover {
    background-image: url("../../static/icons/r_4_y.png");
  }

  &__active{
    
    background-image: url("../../static/icons/r_4_y.png");
  }
}
