.container {
  max-width: 1000px;
  margin: 0 auto;
}

.title {
  text-align: center;
  padding: 60px 0 30px;
  border-bottom: 1px solid #000000;
}

.controls {
  display: flex;
  border-top: 1px solid #313131;

  @media screen and (max-width: 767px) {
    flex-direction: column;
    align-items: center;
  }
}

.ctrl-ct {
  width: 50%;
  display: flex;
  padding-top: 70px;
  flex-direction: column;
  align-items: center;
  height: 610px;
  justify-content: space-between;
}

.off {
  width: 370px;
  height: 123px;
  background-image: url("../../static/icons/play_off.png");
  transition: all 0.2s linear;

  &:hover {
    background-image: url("../../static/icons/play_on.png");
  }
}

.on {
  width: 370px;
  height: 123px;
  background-image: url("../../static/icons/play_on.png");
  transition: all 0.2s linear;

  &:hover {
    background-image: url("../../static/icons/play_off.png");
  }
}

.slider {
  width: 345px;
  transform-origin: left;
  position: absolute;
  top: 0;
  left: 50%;
  transform: rotate(90deg);
}

.sldContainer {
  width: 100%;
  position: relative;
  height: 380px;
  display: flex;
  justify-content: center;
}

input[type="range"] {
  -webkit-appearance: none;
}

input[type="range"] {
  color: #ffc600;
  background-color: #ffc600;
  border-radius: 20px;
  border: none;
  height: 8px;
}

input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  height: 48px;
  width: 48px;
  border-radius: 50%;
  background-color: #ffffff;
  cursor: pointer;
  margin-top: 0px;
  border: 1px solid #d1d1d1;
}

.dotsCont {
  display: flex;
  width: 370px;
  justify-content: space-around;
}

.dot {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: white;

  &__active {
    background-color: #ffc600;
  }
}
