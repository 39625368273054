.ctrl {
  display: flex;
  background-color: #282828;
  width: 310px;
  align-items: center;
  padding: 10px 30px;
  justify-content: space-between;
  margin-top: 5px;
  border: 1px solid #313131;
}

.scr {
  font-size: 3.625rem;
  color: #fefefe;
  background-color: #313131;
  border: 1px solid#434343;
  text-align: center;
  width: 170px;
}

.down,
.up {
  padding: 0;
  border: transparent;
  background: transparent;
  height: 48px;
}

.d {
  background-image: url("../../static/icons/t_d.png");
  width: 48px;
  height: 48px;
  transition: all 0.2s linear;

  &:hover {
    background-image: url("../../static/icons/t_d_y.png");
  }
}

.u {
  background-image: url("../../static/icons/t_u.png");
  width: 48px;
  height: 48px;
  transition: all 0.2s linear;

  &:hover {
    background-image: url("../../static/icons/t_u_y.png");
  }
}
